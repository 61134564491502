/* @import "./CSS/antd.compact.css"; */
@import '~antd/dist/antd.css';
:root {
	--test: rgb(248, 57, 184);

	--navbarHeight: 50px;
	--sidebarOpenWidth: 200px;
	--sidebarClosedWidth: 44px;
	--sidebarFooterHeight: 25px;

	--navbarBackground: #18232D;
	--navbarColor: #fff;

	--sidebarBackground: #18232D;
	--sidebarColor: #fff;

	--contentBackground: #fff;

    --resizeHandleColor: #18232D;

	--eventTableTextColor: #FFF;
	/* --eventTableTextColor: black; */

	--eventStatusOpenGradient: linear-gradient(0deg, rgb(253, 26, 26) 0%, rgb(203, 15, 15) 50%, rgb(255, 99, 99) 100%);
	/* --eventStatusOpenGradient: rgba(255, 18, 18, 0.875); */
	--eventStatusACKGradient: linear-gradient(0deg, rgb(12, 173, 39) 0%, rgb(0, 120, 30) 100%, rgb(0, 100, 20) 100%);
	/* --eventStatusACKGradient: rgba(47, 251, 24, 0.67); */
	--eventStatusSelectedGradient: linear-gradient(0deg, rgb(0, 132, 134) 0%, rgb(27, 65, 216)50%, rgb(0, 140, 200) 100%);
	/* --eventStatusSelectedGradient: rgba(0, 128, 192, 0.76); */
	
	--inputLabelColor: #18232D;

	--offlinePanelStatusRowBg: #ffcccc;
	--onlinePanelStatusRowBg: #ccffcc;
}


body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar {
    width: 12px;
}
::-webkit-scrollbar-track {
    background-color: transparent;
}
::-webkit-scrollbar-thumb {
    background-color: #7a7a7a;
    border-radius: 20px;
    border: 4px solid transparent;
    background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}


.ant-divider-horizontal{
	margin-bottom: 5px !important;
}

.ant-layout-header {
	background-color: var(--navbarBackground);
	color: var(--navbarColor);
	padding: 0;
	height: var(--navbarHeight);
	line-height: var(--navbarHeight);
	border-bottom: 1px solid #e8e8e8;
	font-size: 20px;
	padding: 0px 10px;
}

/* .ant-menu-item{
	border-bottom: 1px solid red;
} */

.ant-menu-inline{
	background-color: var(--navbarBackground) !important;
}
.ant-menu-title-content {
	font-size: 14px;
}
.ant-menu-item-icon{
	font-size: 14px !important;
}

/* style={{width: '100%', background: 'transparent', border: 'none', borderBottom: '1px solid #fff', color: '#fff', fontSize: '14px'}}  */
.navbar-search-input{
	width: 100%;
	background: transparent;
	border: none;
	/* border-right: 1px solid #fff; */
	color: #fff;
	font-size: 14px;
	padding-left: 12px;
}
/* Change the placeholder to center */
/* .navbar-search-input::placeholder { */
	/* width: 100%; */
	/* padding-left: 12px; */
	/* color: rgb(213, 191, 191); */
	/* text-align: center; */
/* } */
/* Remove the ouline on select */
.navbar-search-input:focus{
	outline: none;
}

.sidebar-component{
	height: 100vh;
	overflow-y: auto;
	background-color: var(--sidebarBackground);
	color: var(--sidebarColor);
	/* border-right: 2px solid var(--sidebarColor); */
}

.sidebar-top-logo{
	border-right: 1px solid var(--sidebarColor);
	border-bottom: 1px solid var(--sidebarColor);
	height: var(--navbarHeight);
	line-height: var(--navbarHeight);
	display: inline-flex;
	align-items: center;
	width: 100%;
	/* text-align: left; */
	padding: 0px 10px;
	font-size: 20px;
	overflow: hidden;
	gap: 10px;
	/* justify-content: space-between; */
}

.sidebar-content {
	height: calc(100vh - var(--navbarHeight) - var(--sidebarFooterHeight) - 5px - 30px);		/*last 30px is for the search input*/ 
	overflow: auto;
	justify-content: space-between;
	/* padding-bottom: 100px; */
}

.sidebar-footer {
	padding: 0px 10px;
	border-top: 1px solid #e8e8e8;
	height: var(--sidebarFooterHeight);
	overflow: hidden;
	/* line-height: var(--sidebarFooterHeight); */

	/* text-align: center; */
}

.main-content-div{
	width: 100%;
	padding: 0px 3px;
	background-color: var(--contentBackground);
	height: calc(100vh - var(--navbarHeight));
	overflow: auto;
}

.my-form-outer{
	padding: 6px;
}

.my-form-header{
	display: inline-flex;
	width: 100%;
	justify-content: space-between;
	margin-bottom: 10px;
}

.my-form-title{
	font-size: 20px;
	font-weight: bold;
	color: var(--navbarBackground);
	text-decoration: underline;
}

.my-form-tabpane-outer{
	/* height: 100%;
	overflow: auto; */
	padding-left: 10px;
	padding-bottom: 40px;
}

.my-form-multiple-inline-input{
	display: flex;
	align-items:flex-start;
	flex-direction: column;
	flex-flow: wrap;
	gap: 10px;
	padding-top: 10px;
	/* width: 100%; */
}

.my-table-filter-row{
	display: inline-flex;
	justify-content: space-between;
	align-items: flex-end;
	width: 100%;
	margin-bottom: 4px;
}

.my-form-input{
	width: 100%;
	min-width: 200px;
}

.my-dot {
	height: 20px;
	width: 20px;
	background-color: var(--navbarBackground);
	border-radius: 50%;
	display: inline-block;
}


.site-drawer-render-in-current-wrapper {
	/* position: relative; */
	/* height: 200px; */
	/* padding: 48px; */
	/* overflow: hidden; */
	/* text-align: center; */
	/* background: #fafafa; */
	/* border: 1px solid #ebedf0; */
	border-radius: 2px;
  }
  
.actions-outer-div{
	display: inline-flex;
	gap: 10px;
	/* justify-content: space-between; */
	/* width: 100%; */
}
.actions-button {
	padding: 0px 8px;
	/* font-size: 15px; */
}

.delete-popconfirm{
	width: 500px;
}